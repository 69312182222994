<template>
  <div>
    <sig-form
      :model="model"
      @saved="$emit('saved')"
      @show-sigform="showSigForm"
      @close-sigform="showSigForm(false)"
    >
      <template v-slot:form>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            class="w-full"
            vs-align="center"
            vs-w="4"
          >
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                model.$fields.type.label
              }}</label>
              <v-select
                v-model="model.type"
                :reduce="(option) => option.value"
                @input="typeChanged"
                :clearable="false"
                :options="model.$fields.type.options"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :name="model.$fields.type.name"
                data-vv-delay="500"
                :data-vv-as="'Tipo de quitação'"
                v-validate="model.$fields.type.validate"
              >
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    required="true"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has(model.$fields.type.name)"
              >
                {{ errors.first(model.$fields.type.name) }}
              </span>
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-w="4">
            <vs-checkbox v-model="model.all">{{
              model.$fields.all.label
            }}</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
            <vs-input
              class="w-full"
              data-vv-delay="500"
              :label="labelValue"
              v-model="model.value"
              :name="model.$fields.value.name"
              :data-vv-as="model.$fields.value.label"
              v-validate="model.$fields.value.validate"
              :danger-text="errors.first(model.$fields.value.name)"
              :danger="errors.has(model.$fields.value.name)"
              :success="!errors.has(model.$fields.value.name)"
            />
          </vs-col>
        </vs-row>
      </template>
    </sig-form>
  </div>
</template>

<script>
import model from '@/store/financial/typeDownPay/model'
import SigForm from '@/components/SigForm'

export default {
  components: {
    SigForm
  },
  data () {
    return {
      model, // abrir formulario, modelo vazio
      eventSigForm: true,
      labelValue: 'Parâmetro'
    }
  },
  methods: {
    typeChanged () {
      this.labelValue = this.model.$fields.value.labelValue(this.model)
    },
    register (single) {
      // só existe data se for em uma edição
      if (single && (!this.editing || single.$editing)) {
        this.model = single
      }
      single.$editing = false
      this.editing = false
    },
    showSigForm (value) {
      if (this.eventSigForm) {
        if (value) {
          this.$emit('show-sigform')
          this.register(value)
        } else {
          this.$emit('close-sigform')
          this.model.$fill(this.$store.state)
        }
      }
    }
  }
}
</script>
