var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-col w-full" },
    [
      _c("label", { staticClass: "vs-input--label" }, [
        _vm._v(_vm._s(_vm.model.$fields.value.label)),
      ]),
      _c("v-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: _vm.model.$fields.value.validate,
            expression: "model.$fields.value.validate",
          },
        ],
        attrs: {
          reduce: (option) => option.value,
          clearable: false,
          options: _vm.model.$fields.value.options,
          placeholder: "Selecione",
          dir: _vm.$vs.rtl ? "rtl" : "ltr",
          name: _vm.model.$fields.value.name,
          "data-vv-delay": "500",
          "data-vv-as": _vm.model.$fields.value.label,
          disabled: !_vm.enable,
        },
        on: {
          input: _vm.speciesChanged,
          open: function ($event) {
            return _vm.$emit("click-sigselect", _vm.nameSelect)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function ({}) {
              return [
                _vm._v("\n      Desculpe, nenhum resultado encontrado.\n    "),
              ]
            },
          },
          {
            key: "search",
            fn: function ({ attributes, events }) {
              return [
                _c(
                  "input",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "vs__search",
                        attrs: { required: "true" },
                      },
                      "input",
                      attributes,
                      false
                    ),
                    events
                  )
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.model.value,
          callback: function ($$v) {
            _vm.$set(_vm.model, "value", $$v)
          },
          expression: "model.value",
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has(_vm.model.$fields.value.name),
              expression: "errors.has(model.$fields.value.name)",
            },
          ],
          staticClass: "text-danger text-sm",
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.errors.first(_vm.model.$fields.value.name)) +
              "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }