<template>
  <sig-select
    :model="model"
    :index-select="indexSelect"
    :name-select="nameSelect"
    ref="sigselect"
    @open-sigselect="openSelect"
    @close-sigselect="selectClosed"
    
  >
    <account-plan-list
      :index-select="indexSelect"
      :name-select="nameSelect"
      @selected="selected"
      @search-end="enable(true)"
      @grid-ready="$emit('grid-ready')"
      ref="list"
    />
  </sig-select>
</template>

<script>
import SigSelect from '@/components/SigSelect'
import AccountPlanList from './AccountPlanList'
import model from '@/store/financial/accountPlan/model'

export default {
  components: {
    SigSelect,
    AccountPlanList
  },
  props: ['indexSelect', 'disabled', 'nameSelect'], // index é o indice do objeto selecionado (state.selected)
  data () {
    return {
      model,
      eventSelectClosed: true,
      disable: this.disabled !== undefined ? this.disabled : false
    }
  },
  methods: {
    selected (payload) {
      this.$refs.sigselect.disabled = true
      if (payload) {
        this.$refs.sigselect.close()
        this.$emit('selected', payload)
      }
      this.$emit('close-sigselect')
      this.$refs.sigselect.disabled = false
    },
    refresh () {
      this.$refs.list.refresh()
    },
    showSigform (value) {
      this.eventSelectClosed = value
    },
    openSelect () {
      this.$emit('open-sigselect')
      this.$refs.list.refresh()
    },
    selectClosed () {
      if (this.eventSelectClosed) {
        this.$emit('close-sigselect')
      }
    },
    filter (value) {
      this.$refs.list.filter(value)
    },
    search (value) {
      this.$refs.sigselect.disabled = true
      this.$refs.list.search(value)
    },
    enable (value) {
      this.$refs.sigselect.disabled = !value
    }
  }
}
</script>
