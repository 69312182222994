var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.model.$form.name, active: _vm.isOpen },
          on: {
            "update:active": function ($event) {
              _vm.isOpen = $event
            },
          },
        },
        [
          _vm._t("form"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/1 w-full mt-2" },
              [
                _c(
                  "vs-button",
                  { staticClass: "ml-auto mt-2", on: { click: _vm.save } },
                  [_vm._v("Salvar")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }