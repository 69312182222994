var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sig-list", {
        ref: "list",
        attrs: {
          model: _vm.model,
          "index-select": _vm.indexSelect,
          "allow-search": _vm.allowSearch,
          "name-select": _vm.nameSelect,
        },
        on: {
          selected: _vm.selected,
          "search-end": function ($event) {
            return _vm.$emit("search-end")
          },
          "grid-ready": function ($event) {
            return _vm.$emit("grid-ready")
          },
          saved: function ($event) {
            return _vm.search(false)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }