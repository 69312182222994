<template>
  <vs-row class="w-full" vs-w="12">
    <vs-col
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      :vs-w="column.specie"
    >
      <specie-select
        ref="specie"
        :name-select="modelSpecie.$name"
        @selected="speciesChanged"
        @click-sigselect="specieClicked"
      />
    </vs-col>
    <vs-col
      v-if="specie[0] && (specie[0].value === 'CREDIT_CARD' || specie[0].value === 'DEBIT_CARD')"
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      :vs-w="column.pos"
      class="pl-2"
    >
      <financial-card-select
        ref="financialCard"
        @grid-ready="searchFinancialCard"
        @selected="cardSelected"
        :name-select="modelSpecie.$name"
        @close-sigselect="selectClosed"
        @click-sigselect="selectClicked(true)"
      />
    </vs-col>
    <vs-col
      v-if="
        showTypePayment &&
        specie.length > 0 &&
        (specie[0].value !== 'CARD' || financial_card.length > 0)
      "
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      :vs-w="column.typePayment"
      class="pl-2"
    >
      <type-payment-select
        ref="typePayment"
        @grid-ready="searchTypePayment"
        @close-sigselect="selectClosed"
        @click-sigselect="selectClicked(true)"
        :name-select="modelSpecie.$name"
      />
    </vs-col>
  </vs-row>
</template>

<script>
import modelSpecie from '@/store/financial/specie/model'
import modelFinancialCard from '@/store/financial/financialCard/model'
import modelTypePayment from '@/store/financial/typePayment/model'
import SpecieSelect from '@/components/financial/specie/SpecieSelect'
import FinancialCardSelect from '@/components/financial/financialCard/FinancialCardSelect'
import TypePaymentSelect from '@/components/financial/typePayment/TypePaymentSelect'

export default {
  components: {
    SpecieSelect,
    FinancialCardSelect,
    TypePaymentSelect
  },
  data () {
    return {
      filter: {
        type: null,
        species_id: null,
        species_type: null
      },
      column: {
        specie: 4,
        pos: 4,
        typePayment: 4
      },
      showTypePayment: true,
      showFinancialCard: true,
      modelSpecie
    }
  },
  computed: {
    specie () {
      return this.$store.state[modelSpecie.$store.name].select[modelSpecie.$name].selected
    },
    financial_card () {
      return this.$store.state[modelFinancialCard.$store.name].select[
        this.modelSpecie.$name
      ].selected
    },
    type_payment () {
      return this.$store.getters[modelTypePayment.$store.getters[`selected${modelSpecie.$name}`]]
    }
  },
  methods: {
    speciesChanged () {
      this.$store.commit(
        modelTypePayment.$store.commit.nameSelect,
        modelSpecie.$name
      )
      this.$store.commit(
        modelFinancialCard.$store.commit.nameSelect,
        modelSpecie.$name
      )
      if (this.specie[0].value === 'CARD') {
        this.column = {
          specie: 4,
          pos: 4,
          typePayment: 4
        }
        this.searchFinancialCard()
      } else {
        this.column = {
          specie: 6,
          typePayment: 6,
          pos: 4
        }
        this.filter.species_id = null
        this.searchTypePayment()
      }
    },
    searchTypePayment () {
      if (this.$refs.typePayment) {
        this.filter.type =
          this.specie[0].value !== 'ALL' ? this.specie[0].value : null
        this.$refs.typePayment.filter(this.filter)
        this.$refs.typePayment.search(true)
      }
    },
    searchFinancialCard () {
      if (this.$refs.financialCard) {
        this.showTypePayment = false
        this.$refs.financialCard.search(true)
      }
    },
    cardSelected (data) {
      this.showTypePayment = false
      this.filter.species_id = data.id
      this.showTypePayment = true
    },
    selectClosed (name) {
      if (name === modelSpecie.$name) {
        this.$emit('close-sigselect')
      }
    },
    selectClicked () {
      this.$store.commit(
        modelTypePayment.$store.commit.nameSelect,
        modelSpecie.$name
      )
      this.$store.commit(
        modelSpecie.$store.commit.nameSelect,
        modelSpecie.$name
      )
      this.$store.commit(
        modelFinancialCard.$store.commit.nameSelect,
        modelSpecie.$name
      )
      this.$emit('click-sigselect')
    },
    specieClicked () {
      this.$store.commit(
        modelSpecie.$store.commit.nameSelect,
        modelSpecie.$name
      )
    },
    isValid () {
      let valid = true
      if (!this.specie[0] || !this.specie[0].value) {
        valid = false
        this.mixinNotify(`Selecione um ${modelSpecie.$select.label}`)
      }
      if (!this.type_payment[0] || !this.type_payment[0].id) {
        valid = false
        this.mixinNotify(`${modelTypePayment.$select.label}`)
      }
      return valid
    }
  }
}
</script>
