<template>
  <div>
    <vs-popup
      :title="model.$form.name"
      class="popup90"
      :active.sync="isOpen"
    >
      <slot name="form"></slot>
      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full mt-2">
          <vs-button class="ml-auto mt-2" @click="save">Salvar</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['model'],
  data () {
    return {
    }
  },
  computed: {
    // exibir formulario
    isOpen: {
      get () {
        return this.$store.state[this.model.$store.name].form.isOpen
      },
      set (value) {
        if (value === false) {
          this.popup = false
        }
        this.$store.commit(this.model.$store.commit.activeForm, {
          isOpen: value || false
        })
      }
    },
    popup: {
      get () {
        return this.$store.state[this.model.$store.name].form.popup
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.activeForm, {
          popup: value
        })
      }
    }
  },
  watch: {
    popup (value) {
      if (value) {
        let single = this.$store.state[this.model.$store.name].single.$clone()
        this.$emit('show-sigform', single) // saber que o formulario está sendo aberto.
        single = single.$clone()
        single._editing = false
        this.$store.commit(this.model.$store.commit.single, single)
        const me = this
        this.$store.commit('popup/add', {
          open () {
            me.isOpen = true
          },
          close () {
            me.isOpen = null
          }
        })
      } else {
        this.$store.commit('popup/remove')
      }
    }
  },
  methods: {
    close () {
      this.$emit('close-sigform')
      this.isOpen = false
    },
    async save () {
      const element =  this.$slots.form[0].context.model
      element.$fill(this.$store.state)
      const valid = await this.$slots.form[0].context.$validator.validateAll()
      if (valid) {
        // incluir relacionamentos
        if (element.$validator.valid()) {
          this.$store.commit(this.model.$store.commit.single, element)
          this.$vs.loading()
          this.$store
            .dispatch(this.model.$store.dispatch.saveSingle)
            .then(() => {
              this.mixinNotify('Operação executada com sucesso')
              this.close()
              this.$emit('saved')
            })
            .catch(this.mixinCatch)
            .finally(() => {
              this.$vs.loading.close()
            })
        } else {
          this.mixinNotify(element.$validator.message)
        }
      }
    }
  }
}
</script>