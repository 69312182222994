<template>
  <div>
    <!-- indexSelect é o indice do state.selected -->
    <sig-list
      :model="model"
      :index-select="indexSelect"
      :allow-search="allowSearch"
      ref="list"
      @selected="selected"
      @search-end="$emit('search-end')"
      @grid-ready="$emit('grid-ready')"
      @saved="search(false)"
    >
      <template v-slot:form>
        <type-down-pay-form
          :model="model"
          ref="form"
          @saved="search(false)"
        />
      </template>
    </sig-list>
  </div>
</template>

<script>
import SigList from '@/components/SigList'
import TypeDownPayForm from './TypeDownPayForm.vue'
import model from '@/store/financial/typeDownPay/model'

export default {
  components: {
    SigList,
    TypeDownPayForm
  },
  // indexSelect indice do state.selected
  props: ['indexSelect', 'allowSearch', 'nameSelect'], // allowSearch - Permitir busca automatica no grid
  data () {
    return {
      model
    }
  },
  methods: {
    refresh () {
      this.$refs.list.refresh()
    },
    filter (value) {
      this.$refs.list.filter(value)
    },
    search (value) {
      this.$refs.list.searchUpdate(value)
    },
    selected (payload) {
      this.$emit('selected', payload)
    }
  }
}
</script>
