var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sig-form", {
        attrs: { model: _vm.model },
        on: {
          saved: function ($event) {
            return _vm.$emit("saved")
          },
          "show-sigform": _vm.showSigForm,
          "close-sigform": function ($event) {
            return _vm.showSigForm(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "w-full",
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "4",
                        },
                      },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.model.$fields.account.validate,
                              expression: "model.$fields.account.validate",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "data-vv-delay": "500",
                            label: _vm.model.$fields.account.label,
                            name: _vm.model.$fields.account.name,
                            "data-vv-as": _vm.model.$fields.account.label,
                            "danger-text": _vm.errors.first(
                              _vm.model.$fields.account.name
                            ),
                            danger: _vm.errors.has(
                              _vm.model.$fields.account.name
                            ),
                            success: !_vm.errors.has(
                              _vm.model.$fields.account.name
                            ),
                          },
                          model: {
                            value: _vm.model.account,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "account", $$v)
                            },
                            expression: "model.account",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }