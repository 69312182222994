var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sig-form", {
        attrs: { model: _vm.model },
        on: {
          saved: function ($event) {
            return _vm.$emit("saved")
          },
          "show-sigform": _vm.showSigForm,
          "close-sigform": function ($event) {
            return _vm.showSigForm(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "w-full",
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "4",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c("label", { staticClass: "vs-input--label" }, [
                              _vm._v(_vm._s(_vm.model.$fields.type.label)),
                            ]),
                            _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: _vm.model.$fields.type.validate,
                                  expression: "model.$fields.type.validate",
                                },
                              ],
                              attrs: {
                                reduce: (option) => option.value,
                                clearable: false,
                                options: _vm.model.$fields.type.options,
                                placeholder: "Selecione",
                                dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                name: _vm.model.$fields.type.name,
                                "data-vv-delay": "500",
                                "data-vv-as": "Tipo de quitação",
                              },
                              on: { input: _vm.typeChanged },
                              scopedSlots: _vm._u([
                                {
                                  key: "no-options",
                                  fn: function ({}) {
                                    return [
                                      _vm._v(
                                        "\n                Desculpe, nenhum resultado encontrado.\n              "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "search",
                                  fn: function ({ attributes, events }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "vs__search",
                                              attrs: { required: "true" },
                                            },
                                            "input",
                                            attributes,
                                            false
                                          ),
                                          events
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.model.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "type", $$v)
                                },
                                expression: "model.type",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has(
                                      _vm.model.$fields.type.name
                                    ),
                                    expression:
                                      "errors.has(model.$fields.type.name)",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.errors.first(
                                        _vm.model.$fields.type.name
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-type": "flex", "vs-w": "4" } },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.model.all,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "all", $$v)
                              },
                              expression: "model.all",
                            },
                          },
                          [_vm._v(_vm._s(_vm.model.$fields.all.label))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "8",
                        },
                      },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.model.$fields.value.validate,
                              expression: "model.$fields.value.validate",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "data-vv-delay": "500",
                            label: _vm.labelValue,
                            name: _vm.model.$fields.value.name,
                            "data-vv-as": _vm.model.$fields.value.label,
                            "danger-text": _vm.errors.first(
                              _vm.model.$fields.value.name
                            ),
                            danger: _vm.errors.has(
                              _vm.model.$fields.value.name
                            ),
                            success: !_vm.errors.has(
                              _vm.model.$fields.value.name
                            ),
                          },
                          model: {
                            value: _vm.model.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "value", $$v)
                            },
                            expression: "model.value",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }