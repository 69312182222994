<template>
  <div>
    <sig-list
     :model="model"
      :index-select="indexSelect"
      :allow-search="allowSearch"
      ref="list"
      @selected="selected"
      @search-end="$emit('search-end')"
      @grid-ready="$emit('grid-ready')"
      @saved="search(false)"
    >
      <template v-slot:form>
        <parcel-form
          :model="model"
          ref="form"
          @saved="search(false)"
        />
      </template>
    </sig-list>
  </div>
</template>

<script>
import SigList from '@/components/SigList'
import ParcelForm from './ParcelForm.vue'
import model from '@/store/financial/parcel/model'

export default {
  components: {
    SigList,
    ParcelForm
  },
  // indexSelect - indice do state.selected
  // allowSearch - permitir busca grid api
  props: ['indexSelect', 'allowSearch'],
  data () {
    return {
      model,
      showForm: false
    }
  },
  methods: {
    refresh () {
      this.$refs.list.refresh()
    },
    filter (value) {
      this.$refs.list.filter(value)
    },
    search (value) {
      this.$refs.list.search(value)
    },
    selected (payload) {
      this.$emit('selected', payload)
    }
  }
}
</script>
