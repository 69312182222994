<template>
  <div>
    <sig-form
      :model="model"
      @saved="$emit('saved')"
      @show-sigform="showSigForm"
      @close-sigform="showSigForm(false)"
    >
      <template v-slot:form>
        <!-- descricao -->
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                model.$fields.type_addition.label
              }}</label>
              <v-select
                v-model="model.type_addition"
                :reduce="(option) => option.value"
                :clearable="false"
                :options="model.$fields.type_addition.options"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :name="model.$fields.type_addition.name"
                data-vv-delay="500"
                :data-vv-as="model.$fields.type_addition.name"
                v-validate="model.$fields.type_addition.validate"
              >
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-input
              class="w-full m-2"
              type="number"
              :label="model.$fields.addition.label"
              v-model="model.addition"
              :name="model.$fields.addition.name"
              :data-vv-as="model.$fields.addition.label"
              v-validate="model.$fields.addition.validate"
              :danger="errors.has(model.$fields.addition.name)"
              :danger-text="errors.first(model.$fields.addition.name)"
              :success="!errors.has(model.$fields.addition.name)"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-w="4"> </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                model.$fields.type_interest.label
              }}</label>
              <v-select
                v-model="model.type_interest"
                :reduce="(option) => option.value"
                :clearable="false"
                :options="model.$fields.type_interest.options"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :name="model.$fields.type_interest.name"
                data-vv-delay="500"
                :data-vv-as="model.$fields.type_interest.name"
                v-validate="model.$fields.type_interest.validate"
              >
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                model.$fields.mode_interest.label
              }}</label>
              <v-select
                v-model="model.mode_interest"
                :reduce="(option) => option.value"
                :clearable="false"
                :options="model.$fields.mode_interest.options"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :name="model.$fields.mode_interest.name"
                data-vv-delay="500"
                :data-vv-as="model.$fields.mode_interest.name"
                v-validate="model.$fields.mode_interest.validate"
              >
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-input
              class="w-full m-2"
              type="number"
              :label="model.$fields.interest.label"
              v-model="model.interest"
              :name="model.$fields.interest.name"
              :data-vv-as="model.$fields.interest.label"
              v-validate="model.$fields.interest.validate"
              :danger="errors.has(model.$fields.interest.name)"
              :danger-text="errors.first(model.$fields.interest.name)"
              :success="!errors.has(model.$fields.interest.name)"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                model.$fields.type_fee.label
              }}</label>
              <v-select
                v-model="model.type_fee"
                :reduce="(option) => option.value"
                :clearable="false"
                :options="model.$fields.type_fee.options"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :name="model.$fields.type_fee.name"
                data-vv-delay="500"
                :data-vv-as="model.$fields.type_fee.name"
                v-validate="model.$fields.type_fee.validate"
              >
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-input
              class="w-full m-2"
              type="number"
              :label="model.$fields.fee.label"
              v-model="model.fee"
              :name="model.$fields.fee.name"
              :data-vv-as="model.$fields.fee.label"
              v-validate="model.$fields.fee.validate"
              :danger="errors.has(model.$fields.fee.name)"
              :danger-text="errors.first(model.$fields.fee.name)"
              :success="!errors.has(model.$fields.fee.name)"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-w="4">
            <vs-checkbox v-model="model.fee_comsumer">{{
              model.$fields.fee_comsumer.label
            }}</vs-checkbox>
          </vs-col>
        </vs-row>
         <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <account-plan-select
              @open-sigselect="openSigSelect"
              name-select="parcel"
              ref="accountPlan"
              @grid-ready="searchAccountPlan"
            />
          </vs-col>
        </vs-row>
      </template>
    </sig-form>
  </div>
</template>

<script>
import model from '@/store/financial/parcel/model'
import SigForm from '@/components/SigForm'
import AccountPlanSelect from '@/components/financial/accountPlan/AccountPlanSelect'

export default {
  components: {
    SigForm,
    AccountPlanSelect
  },
  data () {
    return {
      model,
      eventSigForm: true
    }
  },
  computed: {
    accountPlan: {
      get () {
        return this.$store.state[
          this.model.$relations.accountPlan.model.$store.name
        ].select['parcel'].selected
      },
      set (value) {
        this.$store.commit(
          this.model.$relations.accountPlan.model.$store.commit.changeSelected, {
            name: 'parcel',
            data: value
          })
      }
    }
  },
  methods: {
    register (single) {
    // só existe data se for em uma edição
      if (single && (!this.editing || single.$editing)) {
        this.model = single
      }
      single.$editing = false
      this.editing = false
    },
    showSigForm (value) {
      if (this.eventSigForm) {
        if (value) {
          this.register(value)
          this.$emit('show-sigform')
        } else {
          this.$emit('close-sigform')
        }
      }
    },
    searchAccountPlan () {
      if (this.accountPlan.length === 0) {
        this.$store.commit(this.model.$relations.accountPlan.model.$store.commit.nameSelect, model.$name)
        this.$refs.accountPlan.search(true)
      }
    },
    openSigSelect (value) {
      if (value) {
        this.$store.commit(this.model.$relations.accountPlan.model.$store.commit.nameSelect, model.$name)
      }
    }
  }
}
</script>
