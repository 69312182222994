var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sig-select",
    {
      ref: "sigselect",
      attrs: {
        model: _vm.model,
        "index-select": _vm.indexSelect,
        "name-select": _vm.nameSelect,
      },
      on: {
        "click-sigselect": _vm.selectClicked,
        "close-sigselect": _vm.selectClosed,
      },
    },
    [
      _c("type-payment-list", {
        ref: "list",
        attrs: {
          "index-select": _vm.indexSelect,
          "name-select": _vm.nameSelect,
        },
        on: {
          selected: _vm.selected,
          "search-end": function ($event) {
            return _vm.enable(true)
          },
          "grid-ready": function ($event) {
            return _vm.$emit("grid-ready")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }