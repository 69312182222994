<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">{{ label }}</label>
      <vx-input-group
        prependClasses="border border-solid border-grey border-r-0"
      >
        <template slot="prepend">
          <div class="prepend-text bg-primary btn-addon">
            <vs-button @click="changeType">{{ value.type }}</vs-button>
          </div>
        </template>
        <vs-input
          v-if="value.type === '%'"
          key="1__"
          class="w-full"
          type="number"
          v-model="value.value"
          @input="changeInput"
        />
        <vs-input
          v-else
          class="w-full"
          key="2__"
          v-model="value.currencyValue"
          @input="changeInput"
          v-currency="currency"
          ref="value"
        />
      </vx-input-group>
    </vs-col>
  </vs-row>
</template>

<script>
import { CurrencyDirective, CurrencyInput, getValue } from 'vue-currency-input'

export default {
  components: {
    CurrencyInput
  },
  props: {
    value: {},
    currency: {
      default () {
        return {
          currency: { prefix: 'R$ ' },
          valueAsInteger: false,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          valueRange: { min: 0 },
          allowNegative: false
        }
      }
    }, 
    label:{}
  },
  directives: { currency: CurrencyDirective },
  methods: {
    changeType () {
      this.$emit('changeType', this.value.type)
      if (this.value.type === '%') {
        this.value.type = 'R$'
        this.value.currencyValue = 'R$ 0,00'
      } else {
        this.value.type = '%'
        this.value.value = 0
      }
    },
    changeInput () {
      if (this.$refs.value && this.value.type === 'R$' && this.value.currencyValue !== 'R$ 0,00') {
        this.value.value = getValue(this.$refs.value)
      }
      this.$emit('input', this.value)
    }
  },
  mounted () {
    this.value.currencyValue = this.value.currencyValue || this.value.value 
  }
}
</script>