<template>
  <sig-select
    :model="model"
    :index-select="indexSelect"
    :name-select="nameSelect"
    ref="sigselect"
    @click-sigselect="selectClicked"
    @close-sigselect="selectClosed"
  >
    <financial-card-list
     :index-select="indexSelect"
     :name-select="nameSelect"
      @selected="selected"
      @search-end="enable(true)"
      @grid-ready="$emit('grid-ready')"
      ref="list"
    />
  </sig-select>
</template>

<script>
import SigSelect from '@/components/SigSelect'
import FinancialCardList from './FinancialCardList'
import model from '@/store/financial/financialCard/model'

export default {
  components: {
    SigSelect,
    FinancialCardList
  },
  props: ['indexSelect', 'disabled', 'nameSelect'], // index é o indice do objeto selecionado (state.selected)
  data () {
    return {
      model,
      eventSelectClosed: true,
      disable: this.disabled !== undefined  ? this.disabled : false
    }
  },
  methods: {
    selected (payload) {
      this.$refs.sigselect.disabled = true
      if (payload) {
        this.$emit('selected', payload)
        this.$refs.sigselect.close()
      }
      this.$emit('close-sigselect')
      this.$refs.sigselect.disabled = false
    },
    refresh () {
      this.$refs.list.refresh()
    },
    showSigform (value) {
      this.eventSelectClosed = value
      if (this.$refs.sigselect) {
        //this.$refs.sigselect.isOpen = value
      }
    },
    selectClicked () {
      this.$emit('click-sigselect')
      this.$refs.list.refresh()
    },
    selectClosed (payload) {
      if (this.eventSelectClosed) {
        this.$emit('close-sigselect', payload)
      }
    },
    filter (value) {
      this.$refs.list.filter(value)
    },
    search (value) {
      this.$refs.sigselect.disabled = true
      this.$refs.list.search(value)
    },
    enable (value) {
      this.$refs.sigselect.disabled = !value
    }
  }
}
</script>
