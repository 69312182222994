<template>
  <div class="vx-col w-full">
    <label class="vs-input--label">{{ model.$fields.value.label }}</label>
    <v-select
      v-model="model.value"
      :reduce="(option) => option.value"
      @input="speciesChanged"
      @open="$emit('click-sigselect', nameSelect)"
      :clearable="false"
      :options="model.$fields.value.options"
      placeholder="Selecione"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :name="model.$fields.value.name"
      data-vv-delay="500"
      :data-vv-as="model.$fields.value.label"
      v-validate="model.$fields.value.validate"
      :disabled="!enable"
    >
      <template #no-options="{}">
        Desculpe, nenhum resultado encontrado.
      </template>
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          required="true"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
    <span
      class="text-danger text-sm"
      v-show="errors.has(model.$fields.value.name)"
    >
      {{ errors.first(model.$fields.value.name) }}
    </span>
  </div>
</template>

<script>
import model from '@/store/financial/specie/model'

export default {
  components: {},
  data () {
    return {
      model,
      enable: true
    }
  },
  props:['name-select'],
  methods: {
    speciesChanged () {
      this.$store.commit(model.$store.commit.selected, {
        data: this.model
      })
      this.$emit('selected')
    },
    disabled (value) {
      this.enable = !value
    }
  }
}
</script>
