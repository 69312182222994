<template>
  <div>
    <sig-list
      :model="model"
      :index-select="indexSelect"
      :allow-search="allowSearch"
      :name-select="nameSelect"
      ref="list"
      @selected="selected"
      @search-end="$emit('search-end')"
      @grid-ready="$emit('grid-ready')"
      @saved="search(false)"
    >
      <template v-slot:form>
        <type-payment-form
          :model="model"
          ref="form"
          @saved="search(false)"
        />
      </template>
    </sig-list>
  </div>
</template>

<script>
import SigList from '@/components/SigList'
import TypePaymentForm from './TypePaymentForm.vue'
import model from '@/store/financial/typePayment/model'

export default {
  components: {
    SigList,
    TypePaymentForm
  },
  props: ['indexSelect', 'allowSearch', 'nameSelect'], // allowSearch - Permitir busca automatica no grid
  data () {
    return {
      model,
      showForm: false
    }
  },
  computed: {
    isActiveForm () {
      return this.$store.state[this.instance._store.name].isActiveForm
    }
  },
  methods: {
    refresh () {
      this.$refs.list.refresh()
    },
    filter (value) {
      this.$refs.list.filter(value)
    },
    search (value) {
      this.$refs.list.searchUpdate(value)
    },
    selected (payload) {
      this.$emit('selected', payload)
    }
  }
}
</script>
