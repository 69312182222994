<template>
  <div>
    <sig-form
      :model="model"
      @saved="$emit('saved')"
      @show-sigform="showSigForm"
      @close-sigform="showSigForm(false)"
    >
      <template v-slot:form>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-input
              class="w-full"
              :label="model.$fields.name.label"
              :danger="errors.has(model.$fields.name.name)"
              :success="!errors.has(model.$fields.name.name)"
              v-model="model.name"
              :name="model.$fields.name.name"
              :data-vv-as="model.$fields.name.label"
              v-validate="model.$fields.name.validate"
              :danger-text="errors.first(model.$fields.name.name)"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                model.$fields.type_interest.label
              }}</label>
              <v-select
                v-model="model.type_interest"
                :reduce="(option) => option.value"
                :clearable="false"
                :options="model.$fields.type_interest.options"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :name="model.$fields.type_interest.name"
                data-vv-delay="500"
                :data-vv-as="model.$fields.type_interest.name"
                v-validate="model.$fields.type_interest.validate"
              >
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-input
              class="w-full m-2"
              :label="model.$fields.interest.label"
              v-model="model.interest"
              :name="model.$fields.interest.name"
              :data-vv-as="model.$fields.interest.label"
              v-validate="model.$fields.interest.validate"
              :danger="errors.has(model.$fields.interest.name)"
              :danger-text="errors.first(model.$fields.interest.name)"
              :success="!errors.has(model.$fields.interest.name)"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-checkbox v-model="model.interest_comsumer">{{
              model.$fields.interest_comsumer.label
            }}</vs-checkbox>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-checkbox
              @change="type_down_pay = null"
              v-model="model.auto_down"
              >{{ model.$fields.auto_down.label }}</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="8"
            v-if="model.auto_down"
          >
            <type-down-pay-select
              ref="typeDownPay"
              @click-sigselect="openSigSelect"
              @grid-ready="searchTypeDownPay"
              name-select="pos"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <account-select
              @open-sigselect="openSigSelect"
              name-select="pos"
              ref="account"
              @grid-ready="searchAccount"
            />
          </vs-col>
        </vs-row>
      </template>
    </sig-form>
  </div>
</template>

<script>
import SigForm from '@/components/SigForm'
import model from '@/store/financial/financialCard/model'
import TypeDownPaySelect from '@/components/financial/typeDownPay/TypeDownPaySelect'
import AccountSelect from '@/components/financial/account/AccountSelect'

export default {
  components: {
    SigForm,
    TypeDownPaySelect,
    AccountSelect
  },
  data () {
    return {
      model,
      eventSigForm: true,
      typeDownPaySelected: false
    }
  },
  computed: {
    selectTypeDownPayIsOpen () {
      return this.$store.state[this.model.$relations.type_down_pay.model.$store.name].select['pos'].isOpen
    },
    activeForm: {
      get () {
        return this.$store.state[this.model.$store.name].isActiveForm
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.activeForm, value)
      }
    },
    account: {
      get () {
        return this.$store.state[
          this.model.$relations.account.model.$store.name
        ].select['pos'].selected
      },
      set (value) {
        this.$store.commit(
          this.model.$relations.account.model.$store.commit.changeSelected, {
            name: 'pos',
            data: value
          })
      }
    },
    type_down_pay: {
      get () {
        return this.$store.state[
          this.model.$relations.type_down_pay.model.$store.name
        ].select['pos'].selected
      },
      set (value) {
        this.$store.commit(
          this.model.$relations.type_down_pay.model.$store.commit.changeSelected, {
            name: 'pos',
            data: value
          })
      }
    }
  },
  methods: {
    openForm () {
      this.selectClicked(false)
      //this.$store.commit(this.model.$store.commit.activeForm, true)
    },
    register (single) {
      // só existe data se for em uma edição
      if (single) {
        this.model = single
        this.type_down_pay = single.type_down_pay
        this.account = single.account
      }
      single._editing = false
      this.editing = false
    },
    showSigForm (value) {
      // busca consta
      if (this.eventSigForm) {
        if (value) {
          this.$emit('show-sigform')
          this.register(value)
        } else {
          this.$emit('close-sigform')
          this.model.$fill(this.$store.state)
        }
      }
    },
    openSigSelect (value) {
      if (value) {
        this.$store.commit(this.model.$relations.type_down_pay.model.$store.commit.nameSelect, model.$name)
        this.$store.commit(this.model.$relations.account.model.$store.commit.nameSelect, model.$name)
        this.$store.commit(this.model.$relations.account.model.$store.commit.select, {
          name: model.$name,
          openBefore: this.openForm
        })
      }
    },
    searchTypeDownPay () {
      if (this.type_down_pay.length === 0) {
        this.$store.commit(this.model.$relations.type_down_pay.model.$store.commit.nameSelect, model.$name)
        this.$refs.typeDownPay.search(true)
      }
    },
    searchAccount () {
      if (this.account.length === 0) {
        this.$store.commit(this.model.$relations.account.model.$store.commit.nameSelect, model.$name)
        this.$refs.account.search(true)
      }
    }
  }
}
</script>
