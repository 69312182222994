<template>
  <div>
    <sig-form
      :model="model"
      @saved="$emit('saved')"
      @show-sigform="showSigForm"
      @close-sigform="showSigForm(false)"
    >
      <template v-slot:form>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            class="w-full"
            vs-align="center"
            vs-w="4"
          >
            <vs-input
              class="w-full"
              data-vv-delay="500"
              :label="model.$fields.account.label"
              v-model="model.account"
              :name="model.$fields.account.name"
              :data-vv-as="model.$fields.account.label"
              v-validate="model.$fields.account.validate"
              :danger-text="errors.first(model.$fields.account.name)"
              :danger="errors.has(model.$fields.account.name)"
              :success="!errors.has(model.$fields.account.name)"
            />
          </vs-col>
        </vs-row>
      </template>
    </sig-form>
  </div>
</template>

<script>
import model from '@/store/financial/account/model'
import SigForm from '@/components/SigForm'

export default {
  components: {
    SigForm
  },
  data () {
    return {
      model
    }
  },
  computed: {
    single () {
      return this.$store.state[this.model.$store.name].single
    },
    labelValue () {
      return this.$store.state[this.model.$store.name].labelValue
    }
  },
  methods: {
    register (single) {
      // só existe data se for em uma edição
      if (single &&  (!this.editing || single.editing)) {
        this.model = single
      }
      single.editing = false
      this.editing = false
    },
    showSigForm (value) {
      if (this.eventSigForm) {
        if (value) {
          this.$emit('show-sigform')
          this.register(value)
        } else {
          this.$emit('close-sigform')
          this.model.$fill(this.$store.state)
        }
      }
    },
    selectClicked (value) {
      const me = this
      me.editing = true
      setTimeout(function () {
        me.eventSigForm = !value
        me.activeForm = !value
      }, 200)
    }
  }
}
</script>
