<template>
  <div>
    <sig-form
      :model="model"
      @saved="$emit('saved')"
      @show-sigform="showSigForm"
      @close-sigform="showSigForm(false)"
    >
      <template v-slot:form>
        <div style="min-height: 10rem">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              class="w-full"
              vs-align="center"
              vs-w="4"
            >
              <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  model.$fields.type.label
                }}</label>
                <v-select
                  v-model="model.type"
                  :reduce="(option) => option.value"
                  @input="speciesChanged"
                  :clearable="false"
                  :options="model.$fields.type.options"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :name="model.$fields.type.name"
                  data-vv-delay="500"
                  :data-vv-as="model.$fields.type.label"
                  v-validate="model.$fields.type.validate"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      required="true"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has(model.$fields.type.name)"
                >
                  {{ errors.first(model.$fields.type.name) }}
                </span>
              </div>
            </vs-col>
          </vs-row>
          <!-- Selecionar maquineta -->
          <vs-row v-if="model.type === 'CREDIT_CARD' || model.type === 'DEBIT_CARD'">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <financial-card-select
                ref="financialCard"
                @click-sigselect="openSelect(true)"
                @grid-ready="searchFinancialCard"
                :name-select="model.$name"
              />
            </vs-col>
          </vs-row>
          <!-- Selecionar conta -->
          <vs-row
            v-if="model.type && (model.type !== 'CREDIT_CARD' || model.type !== 'DEBIT_CARD' || financial_card[0])"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <account-select
                @open-sigselect="openSelect(true)"
                :name-select="model.$name"
                @grid-ready="searchAccount"
                ref="account"
              />
            </vs-col>
          </vs-row>
          <vs-row v-if="account[0]">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              class="w-full"
              vs-align="center"
              vs-w="4"
            >
              <vs-input
                class="w-full"
                data-vv-delay="500"
                :label="labelName"
                v-model="model.type_payment"
                :name="model.$fields.type_payment.name"
                :data-vv-as="model.$fields.type_payment.label"
                v-validate="model.$fields.type_payment.validate"
                :danger-text="errors.first(model.$fields.type_payment.name)"
                :danger="errors.has(model.$fields.type_payment.name)"
                :success="!errors.has(model.$fields.type_payment.name)"
              />
            </vs-col>
          </vs-row>
          <!-- Selecionar baixa automática -->
          <vs-row v-if="account[0]">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-checkbox
                @change="type_down_pay = null"
                v-model="model.auto_down"
                >{{ model.$fields.auto_down.label }}</vs-checkbox
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="8"
              v-if="model.auto_down"
            >
              <type-down-pay-select
                @click-sigselect="openSelect(true)"
                :name-select="model.$name"
                ref="typeDownPay"
                @grid-ready="searchTypeDownPay"
              />
            </vs-col>
          </vs-row>
          <!-- permite parcelar -->
          <vs-row v-if="account[0]">
            <vs-col vs-type="flex" vs-w="4">
              <vs-checkbox
                @change="parcels = null"
                v-model="model.allow_divide"
                >{{ model.$fields.allow_divide.label }}</vs-checkbox
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
              v-if="model.allow_divide"
            >
              <vs-input
                data-vv-delay="500"
                type="number"
                @input="parcels = null"
                :label="model.$fields.limit_divide.label"
                v-model="model.limit_divide"
                :name="model.$fields.limit_divide.name"
                :data-vv-as="model.$fields.limit_divide.label"
                v-validate="model.$fields.limit_divide.validate"
                :danger-text="errors.first(model.$fields.limit_divide.name)"
                :danger="errors.has(model.$fields.limit_divide.name)"
                :success="!errors.has(model.$fields.limit_divide.name)"
                :disabled="!model.allow_divide"
              />
            </vs-col>
             <vs-col vs-type="flex" vs-w="4"  v-if="model.allow_divide">
              <vs-checkbox
                v-model="model.fee_per_parcel"
                >{{ model.$fields.fee_per_parcel.label }}</vs-checkbox
              >
            </vs-col>
          </vs-row>
          <!-- acrescimo -->
          <vs-row v-if="account[0] && !model.allow_divide">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  model.$fields.type_addition.label
                }}</label>
                <v-select
                  v-model="model.type_addition"
                  :reduce="(option) => option.value"
                  :clearable="false"
                  :options="model.$fields.type_addition.options"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :name="model.$fields.type_addition.name"
                  data-vv-delay="500"
                  :data-vv-as="model.$fields.type_addition.name"
                  v-validate="model.$fields.type_addition.validate"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-input
                class="w-full m-2"
                type="number"
                :label="model.$fields.addition.label"
                v-model="model.addition"
                :name="model.$fields.addition.name"
                :data-vv-as="model.$fields.addition.label"
                v-validate="model.$fields.addition.validate"
                :danger="errors.has(model.$fields.addition.name)"
                :danger-text="errors.first(model.$fields.addition.name)"
                :success="!errors.has(model.$fields.addition.name)"
              />
            </vs-col>
            <vs-col vs-type="flex" vs-w="4">
              <vs-checkbox v-model="model.has_addition">{{
                model.$fields.has_addition.label
              }}</vs-checkbox>
            </vs-col>
          </vs-row>
          <vs-row v-if="account[0] && !model.allow_divide">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <div class="vx-col w-full mr-2">
                <label class="vs-input--label">
                  {{model.$fields.type_interest.label}}
                </label>
                <v-select
                  v-model="model.type_interest"
                  :reduce="(option) => option.value"
                  :clearable="false"
                  :options="model.$fields.type_interest.options"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :name="model.$fields.type_interest.name"
                  data-vv-delay="500"
                  :data-vv-as="model.$fields.type_interest.name"
                  v-validate="model.$fields.type_interest.validate"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="4"
            >
              <vs-input
                data-vv-delay="500"
                :label="model.$fields.interest.label"
                v-model="model.interest"
                :name="model.$fields.interest.name"
                :data-vv-as="model.$fields.interest.label"
                v-validate="model.$fields.interest.validate"
                :danger-text="errors.first(model.$fields.interest.name)"
                :danger="errors.has(model.$fields.interest.name)"
                :success="!errors.has(model.$fields.interest.name)"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-checkbox v-model="model.interest_consumer">{{
                model.$fields.interest_comsumer.label
              }}</vs-checkbox>
            </vs-col>
          </vs-row>



          <vs-row v-if="account[0] && !model.allow_divide">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <div class="vx-col w-full mr-2">
                <label class="vs-input--label">
                  {{model.$fields.type_maximum_discount.label}}
                </label>
                <v-select
                  v-model="model.type_maximum_discount"
                  :reduce="(option) => option.value"
                  :clearable="false"
                  :options="model.$fields.type_maximum_discount.options"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :name="model.$fields.type_maximum_discount.name"
                  data-vv-delay="500"
                  :data-vv-as="model.$fields.type_maximum_discount.name"
                  v-validate="model.$fields.type_maximum_discount.validate"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-input
                data-vv-delay="500"
                :label="model.$fields.maximum_discount.label"
                v-model="model.maximum_discount"
                :name="model.$fields.maximum_discount.name"
                :data-vv-as="model.$fields.maximum_discount.label"
                v-validate="model.$fields.maximum_discount.validate"
                :danger-text="errors.first(model.$fields.maximum_discount.name)"
                :danger="errors.has(model.$fields.maximum_discount.name)"
                :success="!errors.has(model.$fields.maximum_discount.name)"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >

            </vs-col>
          </vs-row>



          <!-- Selecionar Parcela -->
          <span v-if="model.allow_divide && model.limit_divide > 0">
            <vs-row
              v-for="index in parseInt(model.limit_divide) || 0"
              :key="index"
            >
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <!-- Passei o index-select porque o TypePayment hasMany Parcel -->
                <parcel-select
                  :index-select="index - 1"
                  @click-sigselect="openSelect(true)"
                  :name-select="model.$name"
                />
              </vs-col>
            </vs-row>
          </span>
        </div>
      </template>
    </sig-form>
  </div>
</template>

<script>
import model from '@/store/financial/typePayment/model'
import modelSpecie from '@/store/financial/specie/model'
import SigForm from '@/components/SigForm'
import AccountSelect from '@/components/financial/account/AccountSelect'
import FinancialCardSelect from '@/components/financial/financialCard/FinancialCardSelect'
import ParcelSelect from '@/components/financial/parcel/ParcelSelect'
import TypeDownPaySelect from '@/components/financial/typeDownPay/TypeDownPaySelect'

export default {
  components: {
    SigForm,
    AccountSelect,
    FinancialCardSelect,
    ParcelSelect,
    TypeDownPaySelect
  },
  data () {
    return {
      model,
      eventSigForm: true,
      labelName: 'Nome'
    }
  },
  computed: {
    activeForm: {
      get () {
        return this.$store.state[this.model.$store.name].isActiveForm
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.activeForm, value)
      }
    },
    financial_card: {
      get () {
        return this.$store.state[
          this.model.$relations.financial_card.model.$store.name
        ].select[this.model.$name].selected
      },
      set (value) {
        this.$store.commit(
          this.model.$relations.financial_card.model.$store.commit
            .changeSelected,
          {
            name: this.model.$name,
            data: value
          }
        )
      }
    },
    account: {
      get () {
        return this.$store.state[
          this.model.$relations.account.model.$store.name
        ].select[this.model.$name].selected
      },
      set (value) {
        this.$store.commit(
          this.model.$relations.account.model.$store.commit.changeSelected,
          {
            name: this.model.$name,
            data: value
          }
        )
      }
    },
    type_down_pay: {
      get () {
        return this.$store.state[
          this.model.$relations.type_down_pay.model.$store.name
        ].select[this.model.$name].selected
      },
      set (value) {
        this.$store.commit(
          this.model.$relations.type_down_pay.model.$store.commit
            .changeSelected,
          {
            name: this.model.$name,
            data: value
          }
        )
      }
    },
    parcels: {
      get () {
        return this.$store.state[this.model.$relations.parcels.model.$store.name]
          .select[this.model.$name].selected
      },
      set (value) {
        this.$store.commit(
          this.model.$relations.parcels.model.$store.commit.changeSelected,
          {
            name: this.model.$name,
            data: value
          }
        )
      }
    },
    labelValue () {
      return this.$store.state[this.model.$store.name].labelValue
    }
  },
  methods: {
    register (single) {
      // só existe data se for em uma edição
      if (single) {
        this.model = single
        this.account = single.account
        this.type_down_pay = single.type_down_pay
        if (single.type === 'CARD') {
          this.financial_card = single.species || single.financial_card
        }
        this.parcels = single.parcels
      }
      single._editing = false
      this.editing = false
    },
    speciesChanged (value) {
      this.financial_card = null
      this.model.type = value
      this.$store.commit(this.model.$relations.financial_card.model.$store.commit.nameSelect, model.$name)
      this.labelName = this.model.$labelName()
    },
    showSigForm (value) {
      if (this.eventSigForm) {
        if (value) {
          this.$emit('show-sigform')
          this.register(value)
        } else {
          this.$emit('close-sigform')
          this.model.$fill(this.$store.state)
        }
      }
    },
    openSelect (value) {
      const me = this
      me.editing = true
      this.$store.commit(this.model.$relations.type_down_pay.model.$store.commit.nameSelect, model.$name)
      this.$store.commit(this.model.$relations.account.model.$store.commit.nameSelect, model.$name)
      this.$store.commit(this.model.$relations.parcels.model.$store.commit.nameSelect, model.$name)
      this.$store.commit(this.model.$relations.financial_card.model.$store.commit.nameSelect, model.$name)
      this.$store.commit(modelSpecie.$store.commit.nameSelect, model.$name)
      setTimeout(function () {
        me.eventSigForm = !value
        me.activeForm = !value
      }, 200)
    },
    searchTypeDownPay () {
      if (this.type_down_pay.length === 0) {
        this.$refs.typeDownPay.search(true)
      }
    },
    searchFinancialCard () {
      if (this.financial_card.length === 0) {
        this.$refs.financialCard.search(true)
      }
    },
    searchAccount () {
      if (!this.account[0] || !this.account[0].id) {
        this.$refs.account.search(true)
      }
    }
  }
}
</script>
